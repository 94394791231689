import React from "react";
import Note from "../../components/Note";
import PageInfo from "../../components/PageInfo";

import "../../styles/Page.scss";
import Layout from "../../components/Layout";
import interfacepatients from "../../content/tutorial/images/interface-patients.png";
import interfacepatientsavance from "../../content/tutorial/images/interface-patients-avance.png";
import jaugeglobale from "../../content/advanced/images/jauge-globale.png";
import nouveaupatient from "../../content/tutorial/images/nouveau-patient.png";
import ajoutpatient from "../../content/tutorial/images/ajout-john-doe.png";
import confirmation from "../../content/tutorial/images/ajout-patient-confirmation.png";

import {
  WarningOutlined,
  Report,
  SignalCellular4Bar,
  SignalCellular2Bar,
  Chat,
  GppGood,
  Lock
} from "@mui/icons-material";

export default function FeatMonitoring() {
  return (
    <Layout title="Suivi global et détaillé">
      <div className="page-body">
        <PageInfo />
        <div className="page-title">
          <h1>Suivi global et détaillé</h1>
        </div>
        <section>
          <h2 id="vue-globale-suivi-patients">
            La vue globale de suivi des patients
          </h2>
          <p>
            La vue <b>Suivi des patients</b> (menu → onglet <b>Live</b>) vous
            permet d'avoir une ensemble de tous les patients de l'étude, qu'ils
            soient inclus ou non.
          </p>
          <img
            src={interfacepatients}
            alt="Interface Suivi des patients"
            className="fullwidth"
          />
          <p>
            Sur cette page, vous pouvez :
            <li>
              <b>Accéder au dossier d'un patient</b> : en cliquant sur la ligne
              qui lui correspond
            </li>
            <li>
              <b>Rechercher un patient</b> parmi tous les patients de l'étude :
              en cliquant sur la loupe en haut de l'écran puis en tapant son
              identifiant
            </li>
            <li>
              <b>Connaître l'avancement général</b> des inclusions : grâce à la
              barre de progression en haut de l'écran (ne s'affiche que si un
              objectif est défini)
            </li>
            <li>
              <b>Connaître l'avancement détaillé</b> de l'eCRF pour chaque
              patient : en cliquant sur le bouton <em>Vue détaillée</em> en haut
              de l'écran, la vue affiche alors l'avancement de chaque phase
              d'étude pour chaque patient
            </li>
          </p>
        </section>
        <section>
          <h2 id="vue-détaillée-suivi-patients">
            La vue détaillée de suivi des patients
          </h2>
          <img
            src={interfacepatientsavance}
            alt="Interface Suivi des patients avancé"
            className="centered"
          />
          <p>
            La vue détaillée vous permet d'avoir une vision d'ensemble du
            remplissage des formulaires pour tous les patients et par phase.
            L'avancement de chaque phase est représenté par une icône dont voici
            les significations :
          </p>
          <div className="responsive-table">
            <table>
              <tr>
                <td style={{ textAlign: "center" }}>Icône</td>
                <td>Signification de l'icône</td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }}>
                  <SignalCellular4Bar style={{ color: "#B1BABD" }} />
                </td>
                <td>Phase non entamée</td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }}>
                  <SignalCellular2Bar style={{ color: "#3498DB" }} />
                </td>
                <td>Phase entamée</td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }}>
                  <SignalCellular4Bar style={{ color: "#3498DB" }} />
                </td>
                <td>Phase terminée</td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }}>
                  <Lock style={{ color: "#1ABC9C" }} />
                </td>
                <td>Formulaires verrouillés</td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }}>
                  <GppGood style={{ color: "#27AE60" }} />
                </td>
                <td>Formulaires signés</td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }}>
                  <SignalCellular2Bar style={{ color: "#ffc100" }} /> +{" "}
                  <WarningOutlined style={{ color: "#ffc100" }} />
                </td>
                <td>Un formulaire contient un avertissement</td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }}>
                  <SignalCellular2Bar style={{ color: "#E74C3C" }} /> +{" "}
                  <Report style={{ color: "#E74C3C" }} />
                </td>
                <td>Un formulaire contient une erreur critique</td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }}>
                  <SignalCellular2Bar style={{ color: "#3498DB" }} /> +{" "}
                  <Chat style={{ color: "#B1BABD" }} />
                </td>
                <td>Des queries ont été posées</td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }}>
                  <SignalCellular4Bar style={{ color: "#DFE3E4" }} />
                  <SignalCellular2Bar style={{ color: "#ADD5F0" }} />
                  <Lock style={{ color: "#A2E4D7" }} />
                  <br />
                  <span style={{ fontSize: "14px" }}>+ identifiant barré</span>
                </td>
                <td>Le patient a été exclu de l'étude</td>
              </tr>
            </table>
          </div>
          <Note type="tip">
            Vous pouvez accéder directement à une phase de l'étude pour un
            patient donné en cliquant sur l'icône qui lui correspond.
          </Note>
        </section>
        <section>
          <h2 id="barre-de-progression-des-inclusions">
            Barre de progression des inclusions
          </h2>
          <p>
            Si votre étude comporte un objectif d'inclusion (défini par
            l'administrateur de l'étude), une jauge de progression s'affiche en
            haut de la page <b>Suivi des patients</b>.
          </p>
          <img
            src={jaugeglobale}
            alt="Jauge globale d'inclusions"
            className="centered"
          />
          <p>
            La légende est présentée comme ceci : <br />
            <span>
              <b>Nombre de patients actuellement inclus</b> /{" "}
              <b>Objectif d'inclusion</b> (<b>Avancement des inclusions</b> %).
            </span>
          </p>
          <Note type="note">
            Si aucun objectif d'inclusion n'a été paramétré pour l'étude, alors
            la jauge de suivi ne s'affiche pas.
          </Note>
        </section>
        <section>
          <h2 id="ajouter-un-nouveau-patient">Ajouter un nouveau patient</h2>
          <p>
            Depuis la vue <b>Suivi des patients</b>, cliquer sur le bouton{" "}
            <b>Nouveau Patient</b> en bas de l'écran.
          </p>
          <img
            src={nouveaupatient}
            alt="Nouveau patient"
            className="centered"
          />
          <p>
            Remplissez à présent les données du patient puis cliquez sur{" "}
            <b>Ajouter</b> pour valider les informations.
          </p>
          <img
            src={ajoutpatient}
            alt="Ajouter un patient"
            className="centered"
          />
          <Note type="note">
            Il se peut que la longueur du champ "<em>Initiales du patient</em>"
            soit limité à la demande selon l'étude.
            <br />
            Des indications sur l'ordre des initiales peuvent également avoir
            été configurées et s'afficheront à côté du champ si c'est le cas.
          </Note>
          <p>
            Une fenêtre affiche la confirmation de l'ajout du nouveau patient.
            Vous avez alors le choix de :
            <li>
              cliquer sur <b>Aller à l'inclusion</b> pour accéder directement au
              formulaire d'inclusion du patient
            </li>
            <li>
              cliquer sur <b>Retour</b> pour revenir à la liste des patients
            </li>
          </p>
          <img src={confirmation} alt="Confirmation" className="centered" />
        </section>
      </div>
    </Layout>
  );
}
